/* You can add global styles to this file, and also import other style files */
:root {
    --light-orange: rgba(241, 191, 66, 0.72);
    --black-text: rgba(4, 4, 4, 0.9);
}

html,
body {
    
    font-family: "Raleway", sans-serif !important;
    
    padding: 0;
    margin: 0;

    .prefix_style_circle_wrapper {
        display: flex;
        align-items: center;

        // @media screen and (max-width: 700px) {
        //     display: none;
        // }

        .circle {
            width: 30px;
            height: 30px;
            border: 5px solid rgba(241, 191, 66, 1);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .inner_circle {
            width: 14px;
            height: 15px;
            border: 2px solid #ff8200;
            background-color: #ff8200;
            border-radius: 50%;
            animation-name: prefix_circle_animation;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            transform: rotateY(360deg);
        }

        @keyframes prefix_circle_animation {
            0% {
                width: 1px;
                height: 1px;
            }
            50% {
                width: 18px;
                height: 18px;
            }

            // 60% {
            //     width: 20px;
            //     height: 20px;
            // }

            // 70% {
            //     width: 20px;
            //     height: 20px;
            // }

            100% {
                width: 1px;
                height: 1px;
            }
        }

        .line {
            width: 60px;
            height: 5px;
            background-color: rgba(241, 191, 66, 1);
        }
    }
    #navbar_wrapper_section {
        position: sticky;
        display: block;
        top: 0px;
        z-index: 1000;
        margin-top: 40px;
    }
    .navbar {
        background-color: white !important;
        padding: 2px 60px;
        .nav-link{
            font-size:14px;
            font-weight: 700;
         
            @media screen and (max-width: 1284px) {
                font-size:12px;
            }
            @media screen and (max-width: 1185px) {
                font-size:10px;
            }
            @media screen and (max-width: 1024px) {
                font-size:8px;
            }
          
          
        }
        @media screen and (max-width: 600px) {
            padding: 15px 20px;
        }

        .contact_us_button {
            background-color: rgba(255, 130, 0, 1) !important;
            border-color: rgba(255, 130, 0, 1) !important;
            border-radius: 30px;
            padding: 0.5vw 3.5vw;
        }
        .navbar-nav {
            //margin-right: 38px;
        }
        .navbar-brand {
            #ency_logo {
                width: 100px;
            }
        }
        @media (min-width: 992px) {
            .nav-link {
                padding-right: 5px !important;
                padding-left: 5px !important;
            }
        }
    }

    .hero_section {
        display: flex;
        justify-content: space-between;
        padding: 0px 70px;
        padding-top: 50px;
        @media screen and (max-width: 600px) {
            padding: 0px 30px;
        }

        // #whatsapp_icon_wrapper {
        //     display: flex;
        //     align-items: center;
        //     gap: 1vw;
        //     position: fixed;
        //     bottom: 50px;
        //     right: 50px;
        //     z-index: 1;
        //     text-decoration: none;
        //     .whatsapp-icon-text-wrapper {
        //         background: #ffffff;
        //         border: 0.099vw solid rgba(0, 0, 0, 0.24);
        //         box-shadow: 0px 0.3vw 0.7vw rgba(0, 0, 0, 0.25);
        //         border-radius: 0.7vw;
        //         font-family: "Raleway";
        //         font-style: normal;
        //         font-weight: 700;
        //         font-size: 1vw;
        //         text-align: center;
        //         color: rgba(4, 4, 4, 0.6);
        //         padding: 0.8vw;
        //     }
        //     #whatsapp_icon {
        //         width: 62px;
        //         // &:hover {
        //         //     animation: whatsapp_iconspin 0.3s ease-out 1;
        //         //     @keyframes whatsapp_iconspin {
        //         //         100% {
        //         //             -webkit-transform: rotate(360deg);
        //         //         }
        //         //     }
        //         //     @-moz-keyframes whatsapp_iconspin {
        //         //         100% {
        //         //             -moz-transform: rotate(360deg);
        //         //         }
        //         //     }
        //         //     @-webkit-keyframes whatsapp_iconspin {
        //         //         100% {
        //         //             -webkit-transform: rotate(360deg);
        //         //         }
        //         //     }
        //         // }
        //     }
        // }

        .left_content_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            #heading1 {
                line-height: 70px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media screen and (max-width: 600px) {
                    justify-content: flex-start;
                    font-size: 28px;
                }
                #orange-rectangle {
                    width: 27px;
                    height: 13px;
                    background-color: rgba(241, 191, 66, 1);
                    margin: 0px 4.6px;
                    @media screen and (max-width: 600px) {
                        display: none;
                    }
                }
            }
            #heading2 {
                line-height: 90px;
                @media screen and (max-width: 600px) {
                    font-size: 40px;
                }
            }
            #heading3 {
                line-height: 90px;
                color: rgba(0, 184, 255, 1);
                @media screen and (max-width: 600px) {
                    font-size: 37px;
                }
            }

            .get_in_touch_inner {
                display: flex;
                align-items: center;
                gap: 12px;
                position: relative;
                max-width: fit-content;
                #right_arrow {
                    width: 53px;
                    transition: all 0.3s ease;
                }
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 0px;
                    margin-left: 10px;
                    border-bottom: 1px solid #f1bf42;
                    bottom: 2px;
                    -webkit-transform: scaleX(0);
                    -ms-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: -webkit-transform 0.2s ease-in;
                    transition: transform 0.2s ease-in;
                }
                &:hover {
                    &:before {
                        -webkit-transform: scaleX(1);
                        -ms-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                    cursor: pointer;
                    #right_arrow {
                        margin-left: 10px;
                    }
                }
            }
            .follow_us_wrapper {
                display: flex;
                align-items: center;
                gap: 12px;
                .social_media_btn {
                    width: 30px;
                    border-radius: 100%;
                    border: 2px solid rgba(255, 130, 0, 1);
                    padding: 4px;
                    transition: 0.1s;
                    box-shadow: none;
                    &:hover {
                        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
                        width: 35px;
                        cursor: pointer;
                    }
                    
                }
                #divider-line {
                    height: 1.15px;
                    width: 139px;
                    background-color: rgba(255, 130, 0, 1);
                    @media screen and (max-width: 600px) {
                        width: 80px;
                    }
                }
                .follow_us_text {
                    color: rgba(255, 130, 0, 1);
                }
            }
            .animation_wrapper {
                height: 90px;
                overflow: hidden;
            }

            .animation_text {
                margin: 0px;
                padding: 0px;
                @media screen and (max-width: 600px) {
                    font-size: 40px;
                }
                &#text_1 {
                    color: rgba(0, 184, 255, 1);
                }
                &#text_2 {
                    color: rgba(255, 130, 0, 1);
                }
                &#text_3 {
                    // color: rgba(241, 191, 66, 1);
                    color: #F1BF42;
                }
            }

            .animation_text_wrapper {
                position: relative;
                animation-name: example;
                animation-duration: 4s;
                animation-iteration-count: infinite;
            }

            @keyframes example {
                0% {
                    top: 100px;
                }
                20% {
                    top: -5px;
                }
                24% {
                    top: 0px;
                }
                54% {
                    top: -108px;
                }
                58% {
                    top: -98px;
                }
                60% {
                    top: -94px;
                }
                80% {
                    top: -200px;
                }
                84% {
                    top: -196px;
                }
                88% {
                    top: -200px;
                }
                100% {
                    top: -300px;
                }
            }

            @media screen and (max-width: 600px) {
                @keyframes example {
                    0% {
                        top: 100px;
                    }
                    20% {
                        top: 10px;
                    }
                    24% {
                        top: 14px;
                    }
                    54% {
                        top: -52px;
                    }
                    58% {
                        top: -48px;
                    }
                    60% {
                        top: -52px;
                    }
                    80% {
                        top: -108px;
                    }
                    84% {
                        top: -104px;
                    }
                    88% {
                        top: -108px;
                    }
                    100% {
                        top: -180px;
                    }
                }
            }

            #heading3_wrapper {
                display: flex;
                gap: 13px;
            }

            .gap_animation {
                display: flex;
                gap: 13px;
                flex-wrap: wrap;
                .animation_container {
                    display: flex;
                    height: auto;
                    width: 180px;
                    @media screen and (max-width: 600px) {
                        width: 90px;
                    }
                    // font-size: clamp(2rem, 2.8vw, 5rem);
                }

                #hero_gap_text {
                    color: rgba(0, 184, 255, 1);
                    font-size: 7.2vw;
                }

                .animation_typewriter {
                    width: 21.5ch;
                    white-space: nowrap;
                    overflow: hidden;
                    border-right: 4px solid #212121;
                    animation: cursor 1s step-start infinite, text 1.5s steps(6) alternate infinite;
                    line-height: 90px;
                    color: rgba(0, 184, 255, 1);
                    @media screen and (max-width: 600px) {
                        font-size: 40px;
                    }
                }

                @keyframes cursor {
                    0%,
                    100% {
                        border-color: #212121;
                    }
                }

                @keyframes text {
                    0% {
                        width: 0;
                    }
                    100% {
                        width: 205px;
                    }
                }
            }
        }
        .right_content_wrapper {
            position: relative;
            @media screen and (max-width: 1180px) {
                display: none;
            }
            globe_animation:focus{
                outline: none !important;
            }
        }
    }

    .who_we_are_timeline {
        padding: 50px 100px;
        padding-bottom: 106px;
        @media screen and (max-width: 1000px) {
            display: none;
        }
        .content_wrapper {
            display: flex;
            justify-content: space-between;
            #text_prefix_style {
                width: 100px;
            }
            .left {
                display: flex;
                flex-direction: column;
                width: 100%;
                // .left_inner_1 {
                //     display: flex;
                //     justify-content: flex-start;
                // }
                .left_inner_2 {
                    display: flex;
                    justify-content: flex-end;
                    .stack_animation {
                        width: 187px;
                    }
                }
                .left_inner_3 {
                    display: flex;
                    justify-content: center;
                }
            }
            .middle {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                position: relative;
                .circle_animation_wrapper {
                    position: absolute;
                    z-index: -2;
                    bottom: 0px;
                    #who_we_are_circle_animation {
                        width: 230px;
                    }
                }
                .circle {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 179px;
                    height: 179px;
                    border-radius: 50%;
                    background-color: rgba(255, 130, 0, 1);
                    p {
                        line-height: 40px;
                    }
                }
                .background_line {
                    height: 450px;
                    width: 1px;
                    background: rgba(0, 184, 255, 1);
                    position: absolute;
                    top: -20px;
                    z-index: -1;
                }
                .value_text {
                    padding-left: 15px;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                width: 100%;
                .right_inner_1 {
                    display: flex;
                    justify-content: center;
                }
                .right_inner_2 {
                    display: flex;
                    justify-content: flex-end;
                    .stack_animation {
                        width: 187px;
                    }
                }
                .right_inner_3 {
                    // display: flex;
                }
            }
        }
    }

    .who_we_are_timeline_mobile {
        padding: 0px 20px;
        padding-bottom: 80px;
        @media screen and (min-width: 1001px) {
            display: none;
        }
        .prefix_style_circle_wrapper {
            // dont hide on mobile screen for this section only
            display: flex !important;
        }
        .content_wrapper {
            display: flex;
            justify-content: center;
            #stack_animation_r {
                width: 66px;
                position: absolute;
                z-index: -2;
                right: 0;
                bottom: -50px;
            }
            #stack_animation_l {
                width: 66px;
                position: absolute;
                z-index: -2;
                left: -120px;
                bottom: -50px;
            }
            .left_content_wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .middle_content_wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                position: relative;
                .circle {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 102px;
                    height: 102px;
                    border-radius: 50%;
                    background-color: rgba(255, 130, 0, 1);
                    p {
                        line-height: 24px;
                    }
                    #mobile_heading {
                        position: absolute;
                        left: 110%;
                        white-space: nowrap;
                        color: rgba(255, 130, 0, 1);
                    }
                }
                .background_line {
                    height: 800px;
                    width: 1px;
                    background: rgba(255, 130, 0, 1);
                    position: absolute;
                    top: -20px;
                    z-index: -1;
                }
                .value_text {
                    padding-left: 15px;
                }
            }
            .right_content_wrapper {
                margin-top: 150px;
                .right_content_inner {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    gap: 50px;

                    .flex_wrapper {
                        display: flex;
                        position: relative;
                        #text_prefix_style {
                            width: 70px;
                        }
                        &.flex_wrapper_end {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }

    .how_do_we_work_wrapper {
        background-image: url("/assets/how_do_we_bg_animation.webp");
        background-repeat: no-repeat;
        background-size: 500px 435px;
        background-position: right 0px top 10px;
        padding: 100px 0px;
        @media screen and (max-width: 700px) {
            padding: 60px 10px;
        }
        .inner_wrapper {
            display: flex;
            justify-content: space-around;
            .left_content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .our_team_box_list_wrapper {
                    margin-top: 70px;
                }
                .our_team_box {
                    padding: 10px 18px;
                    width: 412px;
                    background: #ffffff;
                    border-radius: 10px;
                    max-width: 90vw;
                    filter: drop-shadow(4px 4px 15px rgba(0, 0, 0, 0.25));
                    margin: 32px 0px;
                    .inner {
                        display: flex;
                        gap: 13px;
                        #people_icon {
                            align-self: flex-start;
                            //     width: 38px;
                            //     height: 30px;
                            filter: invert(54%) sepia(83%) saturate(2268%) hue-rotate(359deg) brightness(100%)
                                contrast(108%);
                            // &.image1_people {
                            //     opacity: 0.6;
                            // }
                        }
                        p {
                            color: rgba(255, 130, 0, 1);
                        }
                    }
                }
                #heading1 {
                    display: flex;
                    gap: 10px;
                    color: rgba(241, 191, 66, 1);
                    #text_prefix_style {
                        width: 108px;
                    }
                }
                #heading2 {
                    line-height: 70px;
                    #e_wrapper {
                        position: relative;
                    }

                    #animation_behind_e {
                        width: 290px;
                        position: absolute;
                        z-index: -1;
                        top: -102px;
                        left: -126px;
                        @media screen and (max-width: 1151px) {
                            display: none;
                        }
                    }
                }
                // #heading2 {
                // #e_background {
                //     background-image: url("assets/work_to_help_client_animation.gif");
                //     background-repeat: no-repeat;
                // }
                // }
                // #e_background{
                //     background-color: red;
                // }
                // #e_background {
                //     background-image: url('assets/work_to_help_client_animation.gif');
                //     background-size: cover;
                //     width: 100px;
                //     height: 100px;
                //     // z-index: 1;
                //     // position: relative;
                // }
                .pointers {
                    display: flex;
                    color: rgba(4, 4, 4, 0.6);
                    align-items: center;
                    gap: 18px;
                    margin: 10px 0px;
                    cursor: pointer;
                    #image1,
                    #image2,
                    #image3,
                    #image4,
                    #image5,
                    #image6 {
                        opacity: 0.7;
                    }
                    // #image1 {
                    // width: 37px;
                    // height: 37px;
                    // opacity: 0.6;
                    // }
                    // #image2 {
                    //     width: 37px;
                    //     height: 37px;
                    //     opacity: 0.6;
                    // }
                    // #image3 {
                    //     width: 37px;
                    //     height: 37px;
                    //     opacity: 0.6;
                    // }
                    // #image4 {
                    //     width: 37px;
                    //     height: 37px;
                    //     opacity: 0.6;
                    // }
                    // #image5 {
                    //     width: 37px;
                    //     height: 37px;
                    //     opacity: 0.6;
                    // }
                    // #image6 {
                    //     width: 37px;
                    //     height: 37px;
                    //     opacity: 0.6;
                    // }
                }
            }
            .right_content {
                // position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                // justify-content: space-between;
                gap:10vw;
                @media screen and (max-width: 1100px) {
                    display: none;
                }
                .arrows_wrapper {
                    display: flex;
                    gap: 19px;
                    .arrow_btn {
                        border: 1px solid rgba(255, 130, 0, 1);
                        border-radius: 100%;
                        color: rgba(255, 130, 0, 1);
                        text-decoration: none;
                        padding: 6px 14px;
                        background-color: white;
                        font-weight: bold;
                        transition: 0.1s;
                        &:hover {
                            cursor: pointer;
                            color: white;
                            background-color: rgba(255, 130, 0, 1);
                        }
                    }
                }
                .right_image_content{
                    .helpClientImageOne {
                        // position: absolute;
                        width: 32vw;
                        // height: 36vw;
                        // z-index: 1;
                        // top: 36vw;
                        // right: 0;
                        // transform: translate(-50%,-50%);
                        // border-radius: 25.6173px;
                      
                    }
                    // .helpClientImageTwo{
                    //     position: absolute;
                    //     top: 13vw;
                    //     left: -11vw;
                    //     width: 24vw;
                    //     height: 36vw;
                    //     object-fit: cover;
                    //     border-radius: 18.9247px;
                    //     opacity: 0.5;
                    // } 
                }
            }
        }
    }

    .multiple_levels_wrapper {
        background-image: url("/assets/how_do_we_bg_animation.webp");
        background-repeat: no-repeat;
        background-size: 340px 250px;
        background-position: right 10px top 10px;
        // padding: 100px 70px;
        padding: 6.5vw 4.5vw;
        @media screen and (max-width: 600px) {
            padding: 90px 30px;
        }
        @media screen and (max-width: 1150px) {
           background-image: none;
        }
        .heading_wrapper {
            display: flex;
            flex-direction: column;
            #heading1 {
                display: flex;
                gap: 10px;
                color: rgba(241, 191, 66, 1);
                #text_prefix_style {
                    width: 108px;
                    @media screen and (max-width: 600px) {
                        display: none;
                    }
                }
            }
            #heading2 {
                line-height: 90px;
                white-space: nowrap;
            }
        }
        .content_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            @media screen and (max-width: 1150px) {
                justify-content: center;
            }
            .left_content {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .data_container_wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                    gap: 10px;
                    .data_container {
                        display: flex;
                        background: white;
                        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
                        align-items: center;
                        width: 16vw;
                        height: 12vh;
                        color: black;
                        margin: 10px 0px;
                        padding: 0px 15px;
                        img {
                            width: 33px;
                        }
                        &.active {
                            background: #ff8200;
                            color: white;
                        }
                    }
                }
                .orange_box {
                    display: flex;
                    justify-content: flex-start;
                    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
                    align-items: center;
                    padding: 30px 34px;
                    // max-height: 15vh;
                    margin: 10px 0px;
                    white-space: nowrap;
                    transition: 0.2s;
                    img {
                        width: 33px;
                    }
                    // &:hover {
                    //     background: #ff8200;
                    //     color: white;
                    //     cursor: pointer;
                    //     img {
                    //         content: url("assets/white_tick.png");
                    //     }
                    // }
                }
            }
            .right_content {
                // position:relative;
                @media screen and (max-width: 1150px) {
                    display: none;
                }
                img {
                    &#image1 {
                        width: 16vw;
                        position: relative;
                        border-radius: 18px;
                        left: 150px;
                        filter: blur(2px);
                    }
                    &#image2 {
                        position: relative;
                        width: 22vw;
                        border-radius: 25px;
                        z-index: 1;
                    }
                    &#image3 {
                        width: 16vw;
                        position: relative;
                        border-radius: 18px;
                        right: 150px;
                        filter: blur(2px);
                    }
                    &#combined_image {
                    //     position: absolute;
                    //     width: 24vw;
                    //     height: 32vw;
                    //     z-index: 1;
                    //     left: 50%;
                    //     // top: 50%;
                    //     top: 9vw;
                    //     transform: translate(-50%,-50%);
                    //     border-radius: 25.6173px;
                      
                    // }
                    // &#combined_image_two{
                    //     height: 20vw;
                        width: 40vw;
                        // object-fit: cover;
                        // border-radius: 18.9247px;
                        // opacity: 0.5;
                    }
                }
            }
        }
    }

    .our_offerings {
        padding: 22px 90px;
        background-color: rgba(48, 171, 240, 0.72);
        color: white;
        @media screen and (max-width: 700px) {
            padding: 70px 30px;
        }
        .inner_wrapper {
            display: flex;
            // flex-direction: column;
            margin-top: 39px;
            justify-content: space-between;
            @media screen and (max-width: 700px) {
                margin-top: 0px;
            }
            @media screen and (max-width: 992px) {
                gap: 3vw;
            }
            .left_content {
                display: flex;
                flex-direction: column;
                gap: 35px;
                @media screen and (min-width: 992px) {
                    width: 58vw;
                }

                .left_content_inner {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 23px;
                    .label_card {
                        background-color: rgba(191, 240, 255, 1);
                        padding: 12px 27px;
                        border-radius: 22px;
                        cursor:pointer;
                        &:hover{
                            // background: rgb(132 75 13 / 22%);
                            background: rgba(255, 130, 0, 1)
                         }
                    }
                }
                .text_image {
                    display: flex;
                    margin-top: 20px;
                    gap: 20px;
                    #text_image_left {
                        display: flex;
                        text-align: justify;
                        flex-direction: column;
                        justify-content: space-between;
                        #we_at_ency {
                            white-space: normal;
                            width: 38vw;
                            @media screen and (max-width: 1279px) {
                                width: 60vw;
                            }
                            @media screen and (max-width: 700px) {
                                width: 85vw;
                            }
                        }
                        #explore_more {
                            display: flex;
                            gap: 15px;
                            align-items: center;
                            margin: 17px 0px;
                            padding-bottom: 10px;
                            max-width: fit-content;
                            position: relative;
                            #next_icon {
                                width: 39px;
                                background-color: white;
                                border-radius: 100%;
                                transition: all 0.3s ease;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 0px;

                                margin-left: 10px;
                                border-bottom: 1px solid white;
                                bottom: 2px;
                                -webkit-transform: scaleX(0);
                                -ms-transform: scaleX(0);
                                transform: scaleX(0);
                                -webkit-transition: -webkit-transform 0.2s ease-in;
                                transition: transform 0.2s ease-in;
                            }
                            &:hover {
                                &:before {
                                    -webkit-transform: scaleX(1);
                                    -ms-transform: scaleX(1);
                                    transform: scaleX(1);
                                }
                                cursor: pointer;
                                #next_icon {
                                    margin-left: 10px;
                                    box-shadow: 0 0 11px rgba(33, 33, 33, 0.4);
                                }
                            }
                        }
                    }

                    #business_people_hands {
                        // width: 340px;
                        width:20vw;
                        height: 13.34vw;
                        border-radius: 40px;
                        filter: drop-shadow(0px 6.09765px 15.2441px rgba(0, 0, 0, 0.25));
                        @media screen and (max-width: 1279px) {
                            display: none;
                        }
                    }
                }
            }
            .right_content {
                // display: flex;
                // flex-direction: column;
                // gap: 55px;
                // text-align: right;
                @media screen and (max-width: 700px) {
                    display: none;
                }
                #prev_icon {
                    display: none;
                }
                .right_menu_items {
                    margin-bottom: 35px;
                    cursor: pointer;
                }
                p {
                    padding: 10px;
                    &#active {
                        width:auto;
                        // display: flex;
                        display: inline-flex;
                        align-items: center;
                       // display: flex;
                       // align-items: center;
                        // justify-content: space-between;
                        gap: 7px;
                        border-right: 3px solid #f1bf42;
                        border-top: 3px solid #f1bf42;
                        #prev_icon {
                            width: 21px;
                            height: 21px;
                            background-color: white;
                            border-radius: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .technologies_wrapper {
        padding: 90px 90px 70px 90px;
        @media screen and (max-width: 700px) {
            padding: 90px 30px;
        }
        #heading_wrapper {
            display: flex;
            align-items: center;
            gap: 22px;
            padding-bottom: 40px;
            #text_prefix_style {
                width: 108px;
                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }
        .content_wrapper {
            display: flex;
            justify-content: space-between;
            //gap:6vw;
            flex-wrap: wrap-reverse;
            .left_content {
                display: flex;
                flex-direction: column;
                gap: 55px;
                max-width: 50.3vw;
                @media screen and (max-width: 530px) {
                    max-width: 90vw;
                }
                .labels_wrapper {
                    display: flex;
                    flex-wrap: wrap;
                  //  gap: 30px;
                  gap:12px;
                    // padding-top: 40px;
                    .label_card {
                        background-color: rgba(241, 191, 66, 0.76);
                        padding: 8px 24px;
                        border-radius: 22px;
                    }
                }
                .explore_more_wrapper {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    position: relative;
                    max-width: fit-content;
                    padding-bottom: 10px;
                    #next_icon {
                        width: 39px;
                        transition: all 0.3s ease;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 0px;
                        margin-left: 10px;
                        border-bottom: 1px solid #ff8200;
                        bottom: 2px;
                        -webkit-transform: scaleX(0);
                        -ms-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transition: -webkit-transform 0.2s ease-in;
                        transition: transform 0.2s ease-in;
                    }
                    &:hover {
                        &:before {
                            -webkit-transform: scaleX(1);
                            -ms-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                        cursor: pointer;
                        #next_icon {
                            margin-left: 10px;
                        }
                    }
                }
            }
            .middle_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                @media screen and (max-width: 1200px) {
                    display: none;
                }
                .middle_content_inner {
                    height: 420px;
                    border: 3px solid #ff8200;
                    border-start-start-radius: 5px;
                    border-start-end-radius: 5px;
                }
                #animation_circle {
                    width: 200px;
                }
            }
            .right_content {
                // display: flex;
                // flex-direction: column;
                // gap: 55px;
                text-align: right;
                @media screen and (max-width: 700px) {
                    margin-bottom: 20px;
                }
                #prev_icon {
                    display: none;
                }
                .right_menu_items {
                    margin-bottom: 55px;
                    cursor: pointer;
                }
                #pointer1 {
                    width:auto;
                    // display: flex;
                    display: inline-flex;
                    align-items: center;
                    // justify-content: space-between;
                    background: rgba(241, 191, 66, 0.76);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                    padding: 7px 18px;
                    gap: 14px;
                    #prev_icon {
                        display: block;
                        width: 30px;
                    }
                }
               
            }
        }
    }

    .software_services_wrapper {
        background-color: rgba(241, 191, 66, 0.6);
        padding: 60px 130px;
        @media screen and (max-width: 700px) {
            padding: 60px 30px;
        }
        .inner_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 29px;
            flex-wrap: wrap;
            // flex-grow: 0;
            // flex-shrink: 0;
            // flex-basis: 40%;
            .left_content {
                display: flex;
                flex-direction: column;
                gap: 32px;
                p {
                    padding: 5px 15px;
                }
                #next_icon {
                    display: none;
                }
                .software_services_list_wrapper{
                    display: flex;
                    align-items: center;
                    gap: 11px;

                    #pointer1 {
                        display: flex;
                        align-items: center;
                        // justify-content: space-between;
                        gap: 11px;
                        border-left: 3px solid rgba(255, 130, 0, 1);
                        border-top: 3px solid rgba(255, 130, 0, 1);
                        #next_icon {
                            display: block;
                            width: 30px;
                            background-color: white;
                            border-radius: 100%;
                        }
                    }
                }
              
            }
            .right_content {
                display: flex;
                flex-direction: column;
                //align-items: center;
                gap: 8px;
                @media screen and (max-width: 700px) {
                    margin-top: 20px;
                }
                .text_wrapper {
                    // display: flex;
                    // align-items: flex-start;
                    // gap: 13px;
                    // .software_services_bullet_list_outer{
                    //     display:inline;  
                            
                    // }
                    .software_services_bullet_list li{
                        display: flex;
                        justify-content:space-around;
                        list-style-type:none;
                    }
                    .software_services_bullet_list li{
                        display:inline;  
                    }
                    #circle_pointer {
                        width: 11px;
                        height: 11px;
                        background: #ffffff;
                        padding: 7px;
                        border: 7px solid #ff8200;
                        border-radius: 100%;
                        margin-top: 3px;
                    }
                    .content {
                        white-space: initial;
                        overflow: hidden;
                        max-width: 440px;
                    }
                  
                }
                .explore_more_wrapper {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    position: relative;
                    max-width: fit-content;
                    padding-bottom: 10px;
                    #next_icon {
                        width: 39px;
                        transition: all 0.3s ease;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 0px;
                        margin-left: 10px;
                        border-bottom: 1px solid #ff8200;
                        bottom: 2px;
                        -webkit-transform: scaleX(0);
                        -ms-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transition: -webkit-transform 0.2s ease-in;
                        transition: transform 0.2s ease-in;
                    }
                    &:hover {
                        &:before {
                            -webkit-transform: scaleX(1);
                            -ms-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                        cursor: pointer;
                        #next_icon {
                            margin-left: 10px;
                        }
                    }
                }
                .explore_more_wrapper1{
                    display: flex;
                    justify-content: flex-end;
                }
                #top_view_img {
                    // width: 40vw;
                    // height: 27vw;
                    width: 36vw;
                    height: 18vw;
                    border-radius: 50px;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    @media screen and (max-width: 700px) {
                        width: 250px;
                        height: 130px;
                    }
                }
            }
        }
    }

    .schedule_a_call_wrapper {
        display: flex;
        background-color: var(--light-orange);
        height: 270px;
        justify-content: center;
        color: var(--black-text);
        padding: 0px 10px;
        @media screen and (max-width: 500px) {
            height: 340px;
        }

        .content_wrapper {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            .left_content {
                #ency_call_button {
                    width: 80px;
                    height: 80px;
                }
                .left_content_inner {
                    display: flex;
                    align-items: center;
                }
            }
            .right_content {
                .start_a_conversation_text {
                    border-bottom: 2px var(--black-text) solid;
                    padding-bottom: 5px;
                }
                .right_content_inner {
                    display: flex;
                    align-items: center;
                    #start_a_convo_anchor {
                        text-decoration: none;
                        color: black;
                        font-size: 1.34vw;
                    }
                    #ency_right_arrow {
                        width: 34px;
                        height: 34px;
                        margin: 0 17px;
                    }
                }
            }
        }
    }
    .our_current_works {
        background-color: rgba(48, 171, 240, 0.72);
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 1140px) {
            padding: 0 20px;
        }
        .our_current_work_heading {
            color: white;
            padding-top: 60px;
            padding-bottom: 80px;
            text-align: center;
            width: 90%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 700px) {
                padding-top: 20px;
                padding-bottom: 20px;
                line-height: 1.2;
            }
            .navigator_wrapper_hidden {
                visibility: hidden;
            }
            .navigator_wrapper {
                display: flex;
                padding-right:2vw;
                // position: absolute;
                // top: 0px;
                // right: 0px;
                gap: 10px;
                @media screen and (max-width: 1140px) {
                    display: none;
                }
                .arrows {
                    font-size: 30px;
                    border: 1px solid white;
                    border-radius: 50%;
                    height: 50px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    transition: 0.1s;
                    &:hover {
                        cursor: pointer;
                        color: rgba(0, 184, 255, 1);
                        background-color: white;
                    }
                }
            }
        }
        .timeline-container {
            *,
            *::before,
            *::after {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
            }
            display: flex;
            justify-content: space-around;
            padding-bottom: 140px;
            width: 100%;
            @media screen and (max-width: 400px) {
                padding-bottom: 160px;
            }

            .vertical-scrollable-timeline {
                width: 600px;
                list-style-type: none;
                position: relative;

                .list-progress {
                    width: 0px;
                    visibility: hidden;
                    height: 100%;
                    background-color: #e803fc;
                    position: absolute;
                    left: 48px;
                    top: 0;
                    overflow: hidden;
                    .inner {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        background-color: #c5c3cb;
                        width: 100%;
                    }
                }
                li {
                    position: relative;
                    padding: 44px 0px 125px 91px;
                    &:last-child {
                        margin-bottom: 0;
                        &::before {
                            height: 0px;
                        }
                    }
                    &.first_li {
                        padding-top: 10px;
                        padding-bottom: 158px;
                    }
                    h2 {
                        font-size: 25px;
                        margin-bottom: 10px;
                        font-weight: 600;
                        color: #fff;
                    }
                    span {
                        line-height: 1.5;
                        color: #fff;
                        position: absolute;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .icon-holder {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 49px;
                        height: 49px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        cursor: pointer;

                        border-radius: 50%;
                        z-index: 1;
                        transition: 0.4s all;

                        &::before {
                            content: "";
                            width: 35px;
                            height: 35px;
                            border: 4px solid #fff;
                            position: absolute;
                            background-color: rgba(241, 191, 66, 1);
                            border-radius: 50%;
                            z-index: -1;
                            transition: 0.4s all;
                        }
                        &.secondary-li {
                            &::before {
                                background-color: white;
                                border: 4px solid rgba(48, 171, 240, 0.72);
                            }
                        }
                        i {
                            font-size: 25px;
                            color: #fff;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 4px;
                        background-color: white;
                        left: 21px;
                        z-index: 0;
                    }
                    &.active {
                        .icon-holder {
                            background-color: #e803fc;
                            &::before {
                                background-color: #e803fc;
                            }
                        }
                    }
                }
            }
            #phone_with_map {
                width: 488px;
                height: 409px;
                object-fit: cover;
                border-radius: 40px;
                background: linear-gradient(100.35deg, rgba(217, 217, 217, 0) 57.72%, #00b8ff 57.72%);
                @media screen and (max-width: 1140px) {
                    display: none;
                }
            }
        }
    }

    .who_we_are_wrapper {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        @media screen and (max-width: 1140px) {
            padding: 0 20px;
        }
        .heading {
            color: black;
            padding-top: 60px;
            padding-bottom: 80px;
            text-align: center;
            @media screen and (max-width: 700px) {
                padding-top: 20px;
                padding-bottom: 20px;
                line-height: 1.2;
            }
        }

        .timeline-container {
            *,
            *::before,
            *::after {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
            }
            display: flex;
            justify-content: space-around;
            padding-bottom: 50px;
            width: 100%;

            .vertical-scrollable-timeline {
                width: 600px;
                list-style-type: none;
                position: relative;
                .list-progress {
                    width: 0px;
                    height: 100%;
                    background-color: #e803fc;
                    position: absolute;
                    left: 48px;
                    top: 0;
                    overflow: hidden;
                    .inner {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        background-color: blue;
                        width: 100%;
                    }
                }
                li {
                    position: relative;
                    padding: 44px 0px 125px 91px;
                    &:last-child {
                        margin-bottom: 0;
                        &::before {
                            height: 0px;
                        }
                    }
                    &.first_li {
                        padding-top: 10px;
                    }
                    h2 {
                        font-size: 25px;
                        margin-bottom: 10px;
                        font-weight: 600;
                        color: black;
                    }
                    span {
                        line-height: 1.5;
                        color: black;
                        position: absolute;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .icon-holder {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 49px;
                        height: 49px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: red;

                        border-radius: 50%;
                        z-index: 1;
                        transition: 0.4s all;

                        &::before {
                            content: "";
                            width: 35px;
                            height: 35px;
                            border: 4px solid red;
                            position: absolute;
                            background-color: rgba(241, 191, 66, 1);
                            border-radius: 50%;
                            z-index: -1;
                            transition: 0.4s all;
                        }
                        &.secondary-li {
                            &::before {
                                background-color: red;
                                border: 4px solid rgba(48, 171, 240, 0.72);
                            }
                        }
                        i {
                            font-size: 25px;
                            color: black;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 4px;
                        background-color: red;
                        left: 21px;
                        z-index: 0;
                    }
                    &.active {
                        .icon-holder {
                            background-color: #e803fc;
                            &::before {
                                background-color: #e803fc;
                            }
                        }
                    }
                }
            }
            #phone_with_map {
                width: 488px;
                height: 409px;
                object-fit: cover;
                border-radius: 40px;
                background: linear-gradient(100.35deg, rgba(217, 217, 217, 0) 57.72%, #00b8ff 57.72%);
                @media screen and (max-width: 1140px) {
                    display: none;
                }
            }
        }
    }

    .footer_wrapper {
        .footer_internal_top_wrapper {
            margin-bottom: 54px;
            padding: 0px 35px 0px 90px;
            padding-top: 7vh !important;
            padding-bottom: 8vh !important;
            .content_wrapper {
                display: flex;
                justify-content: space-between;
                // gap:6vw;
                
                flex-wrap: wrap;
            }
            @media screen and (max-width: 992px) {
                padding: 11vw !important;
            }
        }
        #ency_logo {
            width: 132px;
            margin-left: -0.3vw;
        }
        .second_part_footer_wrapper {
            border-top: 1px solid rgba(0, 0, 0, 0.31);
        }

        .mid_section_outer_wrapper {
            display: flex;
            justify-content: center;
            gap: 60px;
            flex-wrap: wrap;
           align-items: flex-start;
            padding-top: 0.7vw;
            .mid_sections_wrapper {
                display: flex;
                flex-direction: column;
                gap: 15px;
                // text-align: left;
                // @media screen and (max-width: 992px) {
                //     align-items: flex-start;
                // }
            }
        }
        .left_content {
            display: flex;
            flex-direction: column;
            .contact_info_wrapper {
                display: flex;
                flex-direction: column;
                gap: 25px;
                .internal_rows {
                    display: flex;
                    gap: 16px;
                    align-items: center;
                    text-align: left;
                    .ency_desc{
                        width: 30vw;
                            text-align:justify;
                            @media (max-width: 992px) {
                                width: auto !important;
                            }

                    }
                    #pre_icons {
                        width: 25px;
                        border-radius: 50%;
                        transition: 0.1s linear;
                    }
                    &:hover {
                        #pre_icons {
                            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
                            width: 27px;
                        }
                    }
                }
            }
        }
        .right_section {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            #design_image {
                // width: 23vw;
                width:29vw;
                height: auto;
                object-fit: cover;
                aspect-ratio: auto;
                @media (max-width: 992px) {
                    width: 75vw;
                }
            }
            .back_to_top_wrapper , .back_to_top_inner_wrapper{
                display: flex;
                align-items: center;
                text-decoration: none;
        }
           
            .back_to_top_wrapper {
                background-color: #ff8200;
                padding: 0.8vw 1vw;
                position: absolute;
                margin-bottom: 6vw;
                border: 0.2vw solid #f1bf42;
                right: 3.5vw;
                bottom: -1vw;
                div {
                    color: white;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                   
                }
                .up_icon {
                    width: 20px;
                    height: 20px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .follow_us_wrapper {
            display: flex;
            align-items: center;
            gap: 12px;
            .social_media_btn {
                width: 30px;
                border-radius: 100%;
                border: 1px solid rgba(255, 130, 0, 1);
                padding: 4px;
                transition: 0.1s;
                &:hover {
                    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
                    width: 35px;
                    cursor: pointer;
                }
            }
            #divider-line {
                height: 1.15px;
                width: 53px;
                background-color: rgba(255, 130, 0, 1);
            }
            .follow_us_text {
                color: rgba(255, 130, 0, 1);
            }
        }
    }

    .cookie-consent-banner {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2147483645;
        box-sizing: border-box;
        width: 100%;

        background-color: #f1f6f4;
    }

    .cookie-consent-banner__inner {
        max-width: 90vw;
        margin: 0 auto;
        padding: 32px 0;
    }

    .cookie-consent-banner__copy {
        margin-bottom: 16px;
    }

    .cookie-consent-banner__actions {
        display: flex;
        justify-content: flex-end;
    }

    .cookie-consent-banner__header {
        margin-bottom: 8px;

        font-family: "CeraPRO-Bold", sans-serif, arial;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .cookie-consent-banner__description {
        font-family: "CeraPRO-Regular", sans-serif, arial;
        font-weight: normal;
        color: #838f93;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
    }

    .cookie-consent-banner__cta {
        box-sizing: border-box;
        display: inline-block;
        min-width: 130px;
        padding: 11px 13px;

        border-radius: 2px;

        background-color: rgba(255, 130, 0, 1);

        color: #fff;
        text-decoration: none;
        text-align: center;
        font-family: "CeraPRO-Regular", sans-serif, arial;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }

    .cookie-consent-banner__cta--secondary {
        padding: 9px 13px;

        border: 2px solid #3a4649;

        background-color: transparent;

        color: rgba(255, 130, 0, 1);
    }

    .cookie-consent-banner__cta:hover {
        background-color: rgba(255, 130, 0, 1);
    }

    .cookie-consent-banner__cta--secondary:hover {
        border-color: #838f93;

        background-color: transparent;

        color: rgba(255, 130, 0, 1);
    }

    .cookie-consent-banner__cta:last-child {
        margin-left: 16px;
    }

    .style_cursor_pointer {
        cursor: pointer;
    }

    p {
        padding: 0;
        margin: 0;
    }
    .no_underline {
        text-decoration: none;
    }

    .f-13 {
        font-size: 13.4px;
    }
    .f-15 {
        font-size: 15px;
    }
    .f-19 {
        font-size: 19px;
    }
    .f-32 {
        font-size: 32px;
    }
    .f-34 {
        font-size: 34px;
    }
    .f-40 {
        font-size: 40px;
    }
    .f-45 {
        font-size: 45px;
    }
    .f-64 {
        font-size: 64px;
        @media screen and (max-width: 700px) {
            font-size: 44px;
        }
    }
    .f-48 {
        font-size: 48px;
    }
    .f-36 {
        font-size: 36px;
    }
    .f-20 {
        font-size: 20px;
    }
    .f-16 {
        font-size: 16px;
    }
    .fw-600 {
        font-weight: 600;
    }
    .fw-700 {
        font-weight: 700;
    }
    .lh-50 {
        line-height: 50px;
    }
    .lh-40 {
        line-height: 40px;
    }
    .pt-22 {
        padding-top: 22px !important;
    }
    .f-41 {
        font-size: 41px;
    }
    .fw-300 {
        font-weight: 300;
    }
    .f-88 {
        font-size: 88px;
    }
    .f-66 {
        font-size: 66px;
        @media screen and (max-width: 700px) {
            font-size: 60px;
        }
    }
    .fw-700 {
        font-weight: 700;
    }
    .f-22 {
        font-size: 22px;
    }
    .f-24 {
        font-size: 24px;
    }
    .fw-500 {
        font-weight: 500;
    }
    .f-28 {
        font-size: 28px;
        @media screen and (max-width: 700px) {
            font-size: 20px;
        }
    }
    .fw-400 {
        font-weight: 400;
    }
    .text-white {
        color: white;
    }
    .text-black-important {
        color: white !important;
    }
    .text-black {
        color: black;
    }
    .text-black-important {
        color: black !important;
    }
    .text-center {
        text-align: center;
    }
    .text-left {
        text-align: left;
    }
    .f-14 {
        font-size: 14px;
    }
    .f-17 {
        font-size: 17px;
    }
    .f-18 {
        font-size: 18px;
    }
    .f-85 {
        font-size: 85px;
    }
    .f-23 {
        font-size: 23px;
    }
    .fw-bold {
        font-weight: bold;
    }

    #whatsapp_icon_wrapper {
        display: flex;
        align-items: center;
        gap: 1vw;
        position: fixed;
        bottom: 50px;
        right: 50px;
        z-index: 9999;
        text-decoration: none;
        .whatsapp-icon-text-wrapper {
            background: #ffffff;
            border: 0.099vw solid rgba(0, 0, 0, 0.24);
            box-shadow: 0px 0.3vw 0.7vw rgba(0, 0, 0, 0.25);
            border-radius: 0.7vw;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 1vw;
            text-align: center;
            color: rgba(4, 4, 4, 0.6);
            padding: 0.8vw;
            @media screen and (max-width: 992px) {
                font-size: 2.5vw;
                padding: 2vw;
            }
        }
        #whatsapp_icon {
            width: 62px;
            // &:hover {
            //     animation: whatsapp_iconspin 0.3s ease-out 1;
            //     @keyframes whatsapp_iconspin {
            //         100% {
            //             -webkit-transform: rotate(360deg);
            //         }
            //     }
            //     @-moz-keyframes whatsapp_iconspin {
            //         100% {
            //             -moz-transform: rotate(360deg);
            //         }
            //     }
            //     @-webkit-keyframes whatsapp_iconspin {
            //         100% {
            //             -webkit-transform: rotate(360deg);
            //         }
            //     }
            // }
        }
    }
    .black-links {
        .nav-link {
            // color: black !important;
            // color:rgba(4, 4, 4, 0.65) !important;
            color: black !important;
              opacity: 0.8 !important;
         
        }
        #navbar_wrapper_section {
            position: relative !important;
        }
        #ency_logo {
            filter: brightness(1) invert(0) !important;
        }
        .active {
            .downward-triangle {
                opacity: 1 !important;
            }
            .nav-link {
                color: #FF8200  !important;
                // border:2px solid #FF8200 ;
                border-top: 0;
                opacity: 1 !important;
              
            }
        }
        .downward-triangle {
            filter: brightness(0) invert(0) !important;
            opacity: 0.8 !important;
        }
        
        &.position-absolute-navbar {
            #navbar_wrapper_section {
                position: absolute !important;
            }
        }
    }

}



